import { Component, Vue, Prop } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';

highchartsMore(Highcharts);

@Component<BoxPlotChart>({})
export default class BoxPlotChart extends Vue {
  @Prop()
  protected data?: any;

  @Prop()
  protected type?: string;

  protected chartData: any = [];

  protected chart: any;

  protected selectedOptions: string[] = [];

  // loaders
  protected isLoading = true;

  protected mounted() {
    this.chartData = cloneDeep(this.data);
    this.initialize();
  }

  protected initialize() {
    // this.chartData = [
    //   [],
    //   [],
    //   [],
    //   [],
    //   [],
    //   [],
    //   [],
    // ];

    this.parseData(this.chartData);
  }

  protected parseData(data : BoxplotData[]) {
    const serieData : any[] = [];
    const outliers : any[] = [];

    data.forEach((reportType: any, index: number) => {
      // An array of arrays with 6 or 5 values. In this case, the values correspond to x,low,q1,median,q3,high.
      // If the first value is a string, it is applied as the name of the point, and the x value is inferred.
      // The x value can also be omitted, in which case the inner arrays should be of length 5. Then the x value
      // is automatically calculated, either starting at 0 and incremented by 1, or from pointStart and pointInterval
      // given in the series options.
      const reportData : any[] = [
        reportType.min,
        reportType.q1,
        reportType.median,
        reportType.q3,
        reportType.max,
      ];

      serieData.push(reportData);

      Object.values(reportType.higher_outliers).forEach((outlier: any, outlierIndex: number) => {
        outliers.push([index, outlier]);
      });
      Object.values(reportType.lower_outliers).forEach((outlier: any, outlierIndex: number) => {
        outliers.push([index, outlier]);
      });
    });

    console.log(outliers);

    this.initBoxPlot(serieData, outliers);
  }

  protected initBoxPlot(serieData : any[], outliers : any[]) {
    const chartContainer = (this.$refs.chart as any);
    if (! chartContainer) {
      return;
    }

    this.chart = Highcharts.chart(chartContainer, {
      chart: {
        type: 'boxplot',
        height: '600px',
      },
      title: {
        text: 'Verdeling van doorlooptijden',
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: ['Standaard', 'Standaard 30+', 'Zienswijze', 'Specials', 'Bezwaar', 'Aannemersvariant', 'WoCo v1', 'WoCo v2'],
        title: {
          text: 'Variant',
        },
      },
      yAxis: {
        title: {
          text: 'Dagen',
        },
        plotLines: [
          {
            value: 21,
            color: 'red',
            width: 1,
            label: {
              text: 'Doestelling regulier: 21 dagen',
              align: 'center',
              style: {
                color: 'gray',
              },
            },
          },
          {
            value: 35,
            color: 'orange',
            width: 1,
            label: {
              text: 'Doestelling regulier groot: 35 dagen',
              align: 'center',
              style: {
                color: 'gray',
              },
            },
          },
        ],
      },
      series: [
        {
          name: 'Doorlooptijden',
          type: 'boxplot',
          data: serieData,
          tooltip: {
            headerFormat: '<b>{point.key}</b><br/>',
          },
        },
        {
          name: 'Afwijkingen',
          type: 'scatter',
          data: outliers,
          tooltip: {
            pointFormat: 'Afwijking: {point.y}',
          },
        },
      ] as SeriesOptionsType[],
    });
  }
}

interface BoxplotData {
  lower_outlier?: number,
  min?: number,
  q1?: number,
  median?: number,
  q3?: number,
  max?: number,
  higher_outlier?: number,
  higher_outliers?: number[],
  lower_outliers?: number[],
}
