var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Box plot doorlooptijd")
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel elementPanel--spaced fill-height" },
              [
                _vm.isLoading
                  ? _c("LoaderCard", {
                      attrs: { flat: "", type: "spinner--center" }
                    })
                  : _vm._e(),
                !_vm.isLoading
                  ? [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "py-2": "", "align-end": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "", "mb-2": "" } },
                            [
                              _c("PeriodFilter", {
                                attrs: { to: _vm.toDate, from: _vm.fromDate },
                                on: { change: _vm.applyDateFilters }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm2: "" } },
                            [
                              _vm.$store.state.isServiceOrganization
                                ? _c("v-autocomplete", {
                                    staticClass: "with-border",
                                    attrs: {
                                      items: _vm.organizations,
                                      "item-text": "name",
                                      "item-value": "id",
                                      "hide-details": "",
                                      label: "Bureaus"
                                    },
                                    model: {
                                      value: _vm.selectedOrganization,
                                      callback: function($$v) {
                                        _vm.selectedOrganization = $$v
                                      },
                                      expression: "selectedOrganization"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs2: "" } },
                            [
                              _c(
                                "MiButton",
                                {
                                  attrs: {
                                    color: "primary",
                                    small: "true",
                                    outline: "true"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.applyFilters()
                                    }
                                  }
                                },
                                [_vm._v(" Pas filters toe ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.chartData
                        ? _c("BoxPlotChart", { attrs: { data: _vm.chartData } })
                        : _vm._e()
                    ]
                  : _vm._e()
              ],
              2
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }