import { Component, Vue, Watch } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { AxiosError, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import BoxPlotChart from '@/components/box-plot-chart/BoxPlotChart.vue';
import { Organization } from '@/models/Organization';
import { Rpc } from '@/models/Rpc';
import PeriodFilter from '@/components/filters/period-filter/PeriodFilter.vue';

@Component<BoxPlotDoorlooptijd>({
  components: {
    BoxPlotChart,
    PeriodFilter,
  },
})
export default class BoxPlotDoorlooptijd extends Vue {
  public $pageTitle = 'Box plot doorlooptijd';

  // loaders
  protected isLoading = true;

  // organizations
  protected organizations: Organization[] = [];

  protected selectedOrganization = '';

  protected fromDate = '';

  protected toDate = '';

  protected chartData: any | null = null;

  public mounted() {
    this.initialize();
  }

  protected initialize() {
    const lastDayOfMonth = DateTime.local().daysInMonth;
    this.fromDate = `${DateTime.local().toFormat('yyyy-MM')}-01`;
    this.toDate = `${DateTime.local().toFormat('yyyy-MM')}-${lastDayOfMonth}`;
    this.emitBreadcrumb();
    this.getOrganizations();
    this.isLoading = false;
  }

  protected getOrganizations() {
    new Organization()
      .getAllExperts()
      .then((organizations: Organization[]) => {
        this.organizations = organizations;

        if (organizations && organizations[0].id) {
          this.selectedOrganization = organizations[0].id;
        }

        this.applyFilters();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected applyFilters() {
    this.isLoading = true;
    const payload = {
      signature: 'management-reports:boxplot',
      body: {
        from: this.fromDate,
        to: this.toDate,
        organization: this.selectedOrganization,
      },
    };

    new Rpc()
      .rpcPost(payload, false)
      .then((response: AxiosResponse) => {
      // this.parseData(response.data)
        this.chartData = response.data;
        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.isLoading = false;
      });
  }

  protected applyDateFilters(from: string, to: string) {
    this.fromDate = from;
    this.toDate = to;
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Rapportage / Box plot doorlooptijd' },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}
